<template>
  <div>
    <v-dialog v-model="dialogwalletDetails" persistent max-width="600px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <v-icon small class="mr-1">mdi-information</v-icon>
          <div>Wallet Details</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="WalletDetailsDialogEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pb-0  mt-6">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3">
              <span class="font-weight-bold">Wallet Balance :</span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="9">
              <span class="ml-2">{{ ar_balance }} AR</span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
              <span class="font-weight-bold">Wallet Address :</span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="9">
              <span class="ml-2">{{ public_key }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-4 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="WalletDetailsDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
export default {
  props: {
    dialogwalletDetails: Boolean,
    StoreObj: Object,
  },
  data: () => ({
    public_key: "",
    ar_balance: "",
  }),
  watch: {
    dialogwalletDetails(val) {
      if (val == true) {
        this.public_key = this.StoreObj.public_key;
        this.ar_balance = this.StoreObj.ar_balance;
      }
    },
  },
  methods: {
    mouseover() {
      console.log("Over");
      this.public_key = this.StoreObj.public_key;
    },
    mouseleave() {
      console.log("Out");
      this.public_key = "*******************************************";
    },
    WalletDetailsDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
