<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor">
              {{ StoreObj.issuer_name }}
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              label="Search"
              class="FontSize field_label_size field_height borderRadius maxWidthLarge mt-6 mr-3"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="primary" class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-tooltip bottom color="#333333">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  small
                  class="borderRadius"
                  color="primary"
                  @click="IssueCredentialEmit"
                >
                  <v-icon class="mr-1" small>mdi-chevron-double-left</v-icon
                  >Back
                </v-btn>
              </template>
              <span>Back to Issuers</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-data-table
          :search="search"
          dense
          class="elevation-0"
          :headers="GetAllTransactionLogsListHeaders"
          :items="GetAllTransactionLogsList"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetIssuedCredentialsList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.credential_number`]="{ item }">
            <div class="FontSize">
              {{ item.credential_number }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom v-if="item.transaction_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="downloadItem(item)"
                  ><v-icon>mdi-download</v-icon></v-btn
                >
              </template>
              <span>Download</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.transaction_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openViewBlock(item)"
                  ><v-icon>mdi-forward</v-icon></v-btn
                >
              </template>
              <span class="text-center">View On viewblock.io</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.transacted_on`]="{ item }">
            <div class="FontSize" color="primary" dark>
              {{
                `${new Date(item.transacted_on).toDateString() +
                  "," +
                  " " +
                  new Date(item.transacted_on).toLocaleString().split(",")[1]}`
              }}
            </div>
          </template>
          <template v-slot:[`item.mined_at`]="{ item }">
            <div class="FontSize" color="primary" dark>
              {{
                item.mined_at != undefined
                  ? `${new Date(item.mined_at).toDateString() +
                      "," +
                      " " +
                      new Date(item.mined_at).toLocaleString().split(",")[1]}`
                  : "-"
              }}
            </div>
          </template>
          <template v-slot:[`item.fee_in_rupee`]="{ item }">
            <div class="FontSize">
              {{
                item.fee_in_rupee == undefined
                  ? "-"
                  : `${item.fee_in_rupee.toString().slice(0, 4)} INR`
              }}
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="FontSize">
              {{
                item.type == "DOCUMENT"
                  ? "Document"
                  : item.type == "FREE_TEXT"
                  ? "Free Text"
                  : item.type == "TEMPLATE"
                  ? "Template"
                  : "-"
              }}
            </div>
          </template>
          <template v-slot:[`item.source`]="{ item }">
            <div class="FontSize">
              {{ item.source == undefined ? "-" : item.source }}
            </div>
          </template>
          <template v-slot:[`item.issued_using`]="{ item }">
            <div class="FontSize">{{ item.issued_using }}</div>
          </template>
          <template v-slot:[`item.transaction_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.transaction_status == 'ISSUED'
                  ? 'GreenHeader'
                  : item.transaction_status == 'DELAYED'
                  ? 'RedHeader'
                  : 'BlueHeader'
              "
            >
              {{ item.transaction_status }}
            </div>
          </template>
          <!-- <template v-slot:[`item.valid_till`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.valid_till).toLocaleString() }}
            </div>
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllTransactionLogsList } from "@/mixins/GetAllTransactionLogsList.js";
import { GetAllDefaultCurrencyConfiguration } from "@/mixins/GetAllDefaultCurrencyConfiguration.js";
export default {
  mixins: [GetAllTransactionLogsList, GetAllDefaultCurrencyConfiguration],
  components: {
    Overlay,
  },
  props: {
    StoreObj: Object,
  },
  data: () => ({
    search: null,
    overlay: false,
    GetAllTransactionLogsListHeaders: [
      { text: "Transaction ID", value: "credential_number" },
      { text: "Created On", value: "transacted_on" },
      { text: "Issued On", value: "mined_at" },
      { text: "Source", value: "source" },
      { text: "Issued Using", value: "issued_using" },
      { text: "Fee", value: "fee_in_rupee" },
      { text: "Type", value: "type" },
      { text: "Status", value: "transaction_status" },
      // { text: "Valid Till", value: "valid_till" },
    ],
  }),

  mounted() {
    this.GetAllDefaultCurrencyConfigurationMethod();
  },
  created() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  methods: {
    IssueCredentialEmit() {
      this.$emit("clicked");
    },
  },
};
</script>
