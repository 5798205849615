import { ListAllCredentials } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllTransactionLogsList = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllTransactionLogsList: [],
    };
  },
  methods: {
    async GetAllTransactionLogsListMethod(issuer_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListAllCredentials, {
            input: {
              issuer_id: issuer_id,
            },
          })
        );
        this.GetAllTransactionLogsList = JSON.parse(
          result.data.ListAllCredentials
        ).data.items;
        console.log("List Credentials List:", this.GetAllTransactionLogsList);
        for (let i = 0; i < this.GetAllTransactionLogsList.length; i++) {
          if (this.GetAllTransactionLogsList[i].reward != undefined) {
            this.GetAllTransactionLogsList[i].converted_reward = `${(parseInt(
              this.GetAllTransactionLogsList[i].reward
            ) /
              1000000000000) *
              this.currency.dollar *
              this.currency.rupee} `;
          }
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.noDataText = error.errors[0].message;
        this.GetAllTransactionLogsList = [];
      }
    },
  },
};
