<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      :max-width="current_view == 'CREATE' ? '1000px' : current_view == 'ENTER_PASSWORD' ? '400px' : '400px'"
      v-model="dialogCreateIssuerUser">
      <v-card>
        <v-toolbar dark color="secondary" dense class="elevation-0">
          <v-icon small class="mr-2">{{ StoreObj.action == "CREATE" ? "mdi-plus" : "mdi-information" }}</v-icon>
          <div v-text="StoreObj.action == 'CREATE' ? 'Create Issuer' : 'Issuer Details'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createIssuerDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text v-show="current_view == 'CREATE'">
          <v-layout align-end justify-end>
            <v-btn
              v-if="StoreObj.action == 'VIEW' && current_view == 'CREATE' && modify_bool == false"
              small
              class="borderRadius text-capitalize mt-2 mr-3"
              color="secondary"
              @click="modify_bool = true"
              ><v-icon small class="mr-1">mdi-pencil</v-icon>Modify
            </v-btn>
          </v-layout>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-6">
                <div class="heading3 mx-1 font-weight-bold">Business Details :</div>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Organization Name*"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Issuer Type*"
                  :rules="[(v) => !!v || 'Required']"
                  :items="GetAllIssuerTypesList"
                  item-text="issuer_type"
                  item-value="issuer_type"
                  v-model="create_issuer.issuer_type"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Partner*"
                  :rules="[(v) => !!v || 'Required']"
                  :items="GetAllPartnersList"
                  item-text="partner_name"
                  item-value="partner_id"
                  v-model="create_issuer.partner_id"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  class="field_height field_label_size FontSize"
                  label="Country *"
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  :items="GetAllSupportedCountryList"
                  item-value="country_name"
                  item-text="country_name"
                  :rules="[(v) => !!v || 'Country is required']"
                  v-model="create_issuer.country">
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.country_name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Please Select Identification Type"
                  :items="identificationTypeItems"
                  v-model="create_issuer.identification_type"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.actionpencil == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Business Identification No. *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.identification_number"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="12" xs="12" md="4" class="py-0">
                <v-file-input
                  label="Upload Logo*"
                  dense
                  outlined
                  accept="image/png, image/jpeg, image/jpg"
                  prepend-icon=""
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                ></v-file-input>
              </v-col> -->
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0 mt-n3">
                <div class="heading3 mx-1 font-weight-bold">Registered Business Address :</div>
              </v-col>
              <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Address 1 *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_address_1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Address 2 *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_address_2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="State/ County *"
                  :items="stateList"
                  item-text="name"
                  item-value="name"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.state"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="District *"
                  :items="citiesList"
                  item-text="name"
                  item-value="name"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.city"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Area *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.district"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Postal Code *"
                  :rules="[(v) => !!v || 'Required', (v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,6}$/.test(v) || 'Postal Code Is Invalid']"
                  v-model="create_issuer.postal_code"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                <div class="heading3 mx-1 font-weight-bold mt-n3">Business Contact Details :</div>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  class="field_height field_label_size FontSize"
                  label="Name *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.full_name"></v-text-field>
              </v-col>
              <!-- :readonly="StoreObj.action == 'VIEW'" -->
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW'"
                  class="field_height field_label_size FontSize"
                  label="Email ID *"
                  :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Invalid']"
                  v-model="create_issuer.contact_email_id"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  :items="GetAllSupportedCountryList"
                  item-value="country_code"
                  item-text="country_code"
                  class="field_height field_label_size FontSize"
                  label="Country Code *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.country_code">
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.country_code }}
                  </template>
                  <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_code }}) </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class="field_height field_label_size FontSize"
                  label="Contact Number *"
                  :rules="[
                    (v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || 'Invalid',
                    (v) => (v && v.length < 11) || 'Invalid',
                    (v) => (v && v.length > 9) || 'Invalid',
                  ]"
                  v-model="create_issuer.contact_number"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                <div class="heading3 mx-1 font-weight-bold mt-n3">
                  Verification Details :
                </div>
              </v-col> -->
              <!-- <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-checkbox
                  dense
                  class="my-0 py-0"
                  label="Enable Verification Requests"
                  v-model="create_issuer.enable_verification_requests"
                ></v-checkbox>
              </v-col> -->
              <!-- <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Currency Type*"
                  :items="currencyItems"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.currency_type"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :disabled="
                    create_issuer.enable_verification_requests == false
                  "
                  :readonly="StoreObj.action == 'VIEW' && modify_bool == false"
                  class=" field_height field_label_size FontSize"
                  label="Verification Fee *"
                  :rules="[
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,64}$/.test(v) ||
                      'Invalid',
                  ]"
                  v-model="create_issuer.issuer_verification_fee"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                <div class="heading3 mx-1 font-weight-bold mt-n3">
                  Data Encryption :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="py-0">
                <v-checkbox
                  :readonly="StoreObj.action == 'VIEW'"
                  dense
                  class="pa-0 ma-0"
                  v-model="create_issuer.should_encrypt_data"
                  label="Encypt Data on Blockchain"
                ></v-checkbox>
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-show="current_view == 'ENTER_PASSWORD'" class="pb-0">
          <v-form ref="temporary_password">
            <v-text-field
              outlined
              dense
              class="field_height field_label_size FontSize mt-5"
              label="Enter Temporary Password *"
              :rules="[(v) => !!v || 'Required', (v) => (v && v.length > 5) || 'Invalid', (v) => (v && v.length < 21) || 'Invalid']"
              v-model="create_issuer.temporary_password"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-show="current_view == 'UPLOAD_LOGO'" class="">
          <v-row>
            <v-col cols="12" sm="12" md="12" xs="12" align="center">
              <v-card outlined @click="checkImagePresent()" height="200px" width="300px" align="center" class="overflow-hidden mt-4">
                <v-fab-transition v-if="account_logo_url != null && account_logo_url != ''">
                  <v-btn
                    absolute
                    top
                    right
                    dark
                    x-small
                    fab
                    color="red"
                    class="mt-8"
                    @click.stop="(account_logo_url = null), (uploadedImage = false)">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-fab-transition>
                <v-img
                  v-if="account_logo_url != null && account_logo_url != ''"
                  :src="account_logo_url"
                  :lazy-src="account_logo_url"
                  max-width="200px"
                  class="mt-8"></v-img>
                <div v-else>
                  <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="handleClick" />
                  <v-icon class="mt-16">mdi-upload</v-icon>
                  <div align="center">Upload Business Logo</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn small outlined class="borderRadius text-capitalize" color="secondary" @click="createIssuerDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            small
            v-if="current_view != 'CREATE'"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="current_view == 'UPLOAD_LOGO' ? (current_view = 'CREATE') : (current_view = 'UPLOAD_LOGO')"
            ><v-icon small class="mr-1">mdi-chevron-double-left</v-icon>Back</v-btn
          >
          <v-btn
            v-if="current_view == 'CREATE'"
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-chevron-double-right</v-icon>Next
          </v-btn>
          <v-btn
            v-if="current_view == 'UPLOAD_LOGO' && this.StoreObj.action == 'CREATE'"
            small
            class="borderRadius"
            color="primary"
            @click="validateFormMethod()">
            <v-icon small class="mr-1">mdi-chevron-double-right</v-icon>
            <div v-text="account_logo_url == null ? 'Skip' : 'Next'"></div>
          </v-btn>
          <v-btn
            v-if="current_view == 'UPLOAD_LOGO' && this.StoreObj.action == 'VIEW'"
            small
            class="borderRadius"
            color="primary"
            @click="validateFormMethod()">
            <v-icon small class="mr-1">mdi-check</v-icon>
            <div v-text="'Save'"></div>
          </v-btn>
          <v-btn
            v-if="StoreObj.action == 'CREATE' && current_view == 'ENTER_PASSWORD'"
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="validateTemporaryPasswordMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm
          </v-btn>

          <v-btn
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            dark
            v-if="StoreObj.action == 'STATUS_CHANGE'"
            :color="StoreObj.issuer_status == 'ACTIVE' ? 'red' : 'green'"
            @click="changeStatus()"
            ><v-icon small class="mr-1">{{ StoreObj.issuer_status == "ACTIVE" ? "mdi-cancel" : "mdi-check" }}</v-icon>
            <span>{{ StoreObj.issuer_status == "ACTIVE" ? "Deactivate" : "Activate" }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllPartners } from "@/mixins/GetAllPartners.js";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
import { GetAllIssuerTypes } from "@/mixins/GetAllIssuerTypes.js";
import { CreateIssuer, UpdateIssuerDetails } from "@/graphql/mutations.js";
import { GetPresignedUrlForS3Upload } from "../../../mixins/GetsImageUploadS3PreSignedUrl";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    dialogCreateIssuerUser: Boolean,
  },
  mixins: [GetAllCountryCityStates, GetAllSupportedCountry, GetAllIssuerTypes, GetAllPartners, GetPresignedUrlForS3Upload],
  components: {
    Snackbar,
  },
  data: () => ({
    account_logo_url: null,
    height: 0,
    search: null,
    current_view: "CREATE",
    modify_bool: false,
    uploadedImage: false,
    selectedFile: null,
    SnackBarComponent: {},
    create_issuer: {
      account_logo_url: null,
      issuer_name: "",
      issuer_type: "",
      identification_type: "",
      identification_number: "",
      issuer_address_1: "",
      issuer_address_2: "",
      country: "",
      state: "",
      city: "",
      district: "",
      postal_code: "",
      contact_email_id: "",
      contact_user_name: "",
      full_name: "",
      country_code: "",
      contact_number: "",
      temporary_password: "",
      // enable_verification_requests: false,
      // issuer_verification_fee: 0,
      currency_type: "",
      partner_id: "",
      // should_encrypt_data: false,
    },
    typeOfBusinessItems: ["issuer", "Limited Liability Partnership", "Sole Proprietorship", "Individual"],
    stateList: [],
    citiesList: [],
    identificationTypeItems: [],
    loading: false,
    countryList: [
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
    ],
    GetAllSupportedCountryList: [],
    currencyItems: ["INR", "DOLLAR"],
  }),
  watch: {
    current_view(val) {
      if (val == "UPLOAD_LOGO") {
        if (this.StoreObj.action == "EDIT") {
          this.$refs.form.reset();
        }
      }
    },
    "create_issuer.enable_verification_requests"(val) {
      if (val == false) {
        this.create_issuer.currency_type = "INR";
        this.create_issuer.issuer_verification_fee = 0;
      }
    },
    account_logo_url(val) {
      if (val == undefined || val == null || val == "") {
        this.selectedFile = null;
      }
    },
    GetAllSupportedCountryList() {
      if (this.StoreObj.action == "VIEW") {
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.StoreObj.country
        )[1].identification_types;
      }
    },
    dialogCreateIssuerUser(val) {
      if (val == true) {
        // this.create_issuer.enable_verification_requests = false;
        // this.create_issuer.issuer_verification_fee = 0;
        this.getAllCountriesMethod();
        this.GetAllIssuerTypesMethod();
        this.GetAllPartnersMethod();
        console.warn("Check StoreObj", this.StoreObj);
        if (this.StoreObj.action == "VIEW" || this.StoreObj.action == "STATUS_CHANGE") {
          this.create_issuer = {
            issuer_name: this.StoreObj.issuer_name,
            identification_type: this.StoreObj.identification_type,
            identification_number: this.StoreObj.identification_number,
            issuer_address_1: this.StoreObj.issuer_address_1,
            issuer_address_2: this.StoreObj.issuer_address_2,
            country: this.StoreObj.country,
            state: this.StoreObj.state,
            city: this.StoreObj.city,
            postal_code: this.StoreObj.postal_code,
            contact_email_id: this.StoreObj.contact_email_id,
            // contact_user_name: this.StoreObj.contact_user_name,
            full_name: this.StoreObj.full_name,
            contact_number: this.StoreObj.contact_number,
            country_code: this.StoreObj.country_code,
            issuer_type: this.StoreObj.issuer_type,
            district: this.StoreObj.district,
            account_logo_url: this.StoreObj.account_logo_url,
            // enable_verification_requests: this.StoreObj
            //   .enable_verification_requests,
            // issuer_verification_fee: this.StoreObj.issuer_verification_fee,
            currency_type: this.StoreObj.currency_type,
            partner_id: this.StoreObj.partner_id,
            // should_encrypt_data: this.StoreObj.should_encrypt_data,
          };
        }
      }
    },
    "create_issuer.country"(val) {
      if (this.dialogCreateIssuerUser == true && val != null && val != undefined && val != "" && this.GetAllSupportedCountryList.length !== 0) {
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.create_issuer.country
        )[0].identification_types;
        this.getAllStatesMethod(val);
        if (this.StoreObj.action == "CREATE") {
          this.create_issuer.state = "";
          this.create_issuer.city = "";

          this.create_issuer.currency_type = this.countryList.filter((country) => country.name == val)[0].currency;
          if (this.$refs.form != undefined) {
            this.$refs.form.resetValidation();
          }
        }
      }
    },
    "create_issuer.state"(val) {
      if (this.dialogCreateIssuerUser == true && val != null && val != undefined && val != "" && this.GetAllSupportedCountryList.length !== 0) {
        this.getAllCitiesMethod(val);
      }
    },
  },
  mounted() {
    this.GetAllSupportedCountryMethod("ACTIVE");
    this.height = window.innerHeight - 150;
  },
  methods: {
    async uploadImageToS3() {
      var self = this;
      this.loading = true;
      const key = `BMS/Issuer/account_logo/${new Date().getTime()}_${self.selectedFile.name}`;
      await self.GetPresignedUrlForS3UploadMethod(key);
      const response = await this.https3UploadFunction();
      if (response == "Success") {
        var s3URL = `https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/${encodeURI(key)}`;
        if (self.StoreObj.action == "CREATE") {
          self.createIssuerMethod(s3URL);
        } else if (self.StoreObj.action) {
          self.editIssuerMethod(s3URL);
        }
      }

      // var s3Bucket = new AWS.S3({
      //   region: "ap-south-1",
      //   accessKeyId: "AKIARIY6IPQHVSZZXGYA",
      //   secretAccessKey: "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",
      // });
      // var fileReader = new FileReader();
      // fileReader.readAsDataURL(self.selectedFile);
      // fileReader.onload = async function () {
      //   var base64FileContent = fileReader.result.split(",");
      //   var buf = Buffer.from(base64FileContent[1], "base64");
      //   var param = {
      //     Bucket: "workhistory-mumbai",
      //     Key: `BMS/Issuer/account_logo/${new Date().getTime()}_${self.selectedFile.name}`,
      //     Body: buf,
      //     ContentType: self.selectedFile.type,
      //     ACL: "public-read-write",
      //   };
      //   await s3Bucket.putObject(param, function (err, data) {
      //     if (err) {
      //       console.log(err);
      //       self.loading = false;
      //     } else if (data) {
      //       var s3URL = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${encodeURI(param.Key)}`;
      //       if (self.StoreObj.action == "CREATE") {
      //         self.createIssuerMethod(s3URL);
      //       } else if (self.StoreObj.action) {
      //         self.editIssuerMethod(s3URL);
      //       }
      //     }
      //   });
      // };
    },
    async https3UploadFunction() {
      const response = await axios.put(this.PresignedUrlForS3Upload, this.selectedFile, {
        headers: {
          "Content-Type": this.selectedFile.type,
        },
      });

      if (response.status == 200 && response.statusText == "OK") {
        return "Success";
      } else {
        this.loading = false;
        console.log("Error uploading file", response);
      }
    },
    checkImagePresent() {
      if (this.account_logo_url == null || this.account_logo_url == "" || this.account_logo_url == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function (val) {
        self.account_logo_url = val.target.result;
      };
    },
    validateTemporaryPasswordMethod() {
      if (this.$refs.temporary_password.validate()) {
        if (this.account_logo_url == null) {
          this.createIssuerMethod();
        } else {
          this.uploadImageToS3();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Temporary Password",
        };
      }
    },
    async editIssuerMethod(s3URL) {
      try {
        var inputParams = {
          issuer_id: this.StoreObj.issuer_id,
          user_email_id: this.$store.getters.get_user_email,
          issuer_name: this.create_issuer.issuer_name,
          postal_code: this.create_issuer.postal_code,
          issuer_address_1: this.create_issuer.issuer_address_1,
          issuer_address_2: this.create_issuer.issuer_address_2,
          city: this.create_issuer.city,
          contact_number: this.create_issuer.contact_number,
          identification_type: this.create_issuer.identification_type,
          identification_number: this.create_issuer.identification_number,
          account_logo_url: this.create_issuer.account_logo_url,
          // enable_verification_requests: this.create_issuer
          //   .enable_verification_requests,
          // issuer_verification_fee: this.create_issuer.issuer_verification_fee,
        };
        if (s3URL != undefined) {
          inputParams.account_logo_url = s3URL;
        }
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(UpdateIssuerDetails, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerDetails);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createIssuerDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    validateFormMethod() {
      if (this.current_view == "CREATE") {
        if (this.$refs.form.validate()) {
          this.current_view = "UPLOAD_LOGO";
          if (this.StoreObj.action == "VIEW") {
            if (this.StoreObj.account_logo_url != undefined && this.StoreObj.account_logo_url != null && this.StoreObj.account_logo_url != "") {
              this.account_logo_url = this.StoreObj.account_logo_url;
              this.uploadedImage = true;
            }
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisk(*) are mandatory",
          };
        }
      } else if (this.current_view == "UPLOAD_LOGO") {
        if (this.StoreObj.action == "CREATE") {
          this.current_view = "ENTER_PASSWORD";
        } else if (this.StoreObj.action == "VIEW") {
          if (this.selectedFile == null) {
            this.editIssuerMethod();
          } else {
            this.uploadImageToS3();
          }
        }
      }
    },
    async createIssuerMethod(s3URL) {
      var inputParams = this.create_issuer;
      if (s3URL != undefined) {
        inputParams.account_logo_url = s3URL;
      }
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(CreateIssuer, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateIssuer);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createIssuerDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createIssuerDialogEmit(Toggle) {
      this.modify_bool = false;
      this.current_view = "CREATE";
      this.identificationTypeItems = [];
      this.selectedFile = null;
      this.account_logo_url = null;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      if (this.$refs.temporary_password != undefined) {
        this.$refs.temporary_password.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
