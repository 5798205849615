<template>
  <v-card class="elevation-0">
    <v-row dense no-gutters>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-toolbar class="elevation-0">
          <div class="PrimaryFontColor heading1">
            Credit History
          </div>
        </v-toolbar>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-tooltip bottom color="#474747">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-on="on"
                v-bind="attrs"
                color="primary"
                class="borderRadius"
                @click="creditHistoryEmit()"
              >
                <v-icon small class="mr-1">mdi-chevron-double-left</v-icon>
                Back
              </v-btn>
            </template>
            <span>Back to Issuers</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-card-text class="py-0">
      <v-data-table
        dense
        class="elevation-0"
        :headers="creditHistoryHeaders"
        :items="StoreObj.comments"
        :disable-sort="$vuetify.breakpoint.xs ? true : false"
      >
        <template v-slot:[`item.sl_no`]="{ item }">
          <div class="FontSize">
            {{ StoreObj.comments.indexOf(item) + 1 }}
          </div>
        </template>
        <template v-slot:[`item.commented_on`]="{ item }">
          <div class="FontSize">
            {{ new Date(item.commented_on).toLocaleString() }}
          </div>
        </template>
        <template v-slot:[`item.commented_by`]="{ item }">
          <div class="FontSize">
            {{ item.commented_by }}
          </div>
        </template>
        <template v-slot:[`item.comment`]="{ item }">
          <div class="FontSize">
            {{ item.comment }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    StoreObj: Object,
  },
  data: () => ({
    creditHistoryHeaders: [
      {
        text: "SL. No",
        value: "sl_no",
      },
      {
        text: "Commented On",
        value: "commented_on",
      },
      {
        text: "Commented By",
        value: "commented_by",
      },
      {
        text: "Comments",
        value: "comment",
      },
    ],
  }),
  methods: {
    creditHistoryEmit() {
      this.$emit("clicked");
    },
  },
};
</script>
