import { ListIssuersSubscriptionFilter } from "@/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuerUsersFilter = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllIssuerUsersFilterList: [],
    };
  },
  methods: {
    async GetAllIssuerUsersFilterMethod(verification_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListIssuersSubscriptionFilter, {
            input: {
              verification_status: verification_status,
            },
          })
        );
        this.GetAllIssuerUsersFilterList = JSON.parse(
          result.data.ListIssuersSubscriptionFilter
        ).data.items;
        console.warn("GetAllIssuerUsersFilterList", this.GetAllIssuerUsersFilterList);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllIssuerUsersFilterList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
