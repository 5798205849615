<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <WalletDetailsDialog
      :dialogwalletDetails="dialogwalletDetails"
      :StoreObj="StoreObj"
      @clicked="WalletDetailsDialogEmit"
    />
    <CreateIssuerDialog
      :StoreObj="StoreObj"
      :dialogCreateIssuerUser="dialogCreateIssuerUser"
      @clicked="createIssuerDialogEmit"
    />
    <AddRemoveCredit
      :dialogAddRemoveCredit="dialogAddRemoveCredit"
      :StoreObj="StoreObj"
      @clicked="addRemoveCreditDialogEmit"
    />
    <ActivateDeactivateIssuerDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateIssuer="dialogActivateInactivateIssuer"
      @clicked="dialogActivateInactivateIssuerEmit"
    />
    <VerifyIssuerOrganization
      :dialogVerifyIssuerOrganization="dialogVerifyIssuerOrganization"
      :StoreObj="StoreObj"
      @clicked="dialogVerifyIssuerOrganizationEmit"
    />
    <v-card class="elevation-0" v-if="current_view == 'LIST'">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">Issuers</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              label="Status"
              class="FontSize field_label_size field_height borderRadius mt-6 mr-3 maxWidthSmall"
              v-model="verification_status"
              :items="verification_statusItems"
            ></v-select>
            <v-text-field
              dense
              outlined
              label="Search Issuers"
              class="FontSize field_label_size field_height borderRadius mt-6 mr-3"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <!-- <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  color="secondary"
                  v-on:click="dialogCreateIssuerUser = true"
                  @click="StoreObj.action = 'CREATE'"
                  class="borderRadius"
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
                >
              </template>
              <span>Create Issuer</span>
            </v-tooltip> -->
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :search="search"
          :headers="userHeaders"
          :no-data-text="noDataText"
          :items="GetAllIssuerUsersFilterList"
          :hide-default-footer="GetAllIssuerUsersFilterList.length == 0"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllIssuerUsersFilterList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.partner_code`]="{ item }">
            <div class="FontSize">
              {{ item.partner_code != undefined ? item.partner_code : "-" }}
            </div>
          </template>
          <template v-slot:[`item.issuer_name`]="{ item }">
            <!-- <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }"> -->
            <div class="FontSize">
              <!-- {{
                    item.issuer_name.length > 10
                      ? `${item.issuer_name.slice(0, 10)}..`
                      : item.issuer_name
                  }} -->
              {{ item.issuer_name }}
            </div>
            <!-- </template>
              <span class="FontSize">{{ item.issuer_name }}</span>
            </v-tooltip> -->
          </template>
          <template v-slot:[`item.contact_email_id`]="{ item }">
            <!-- <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }"> -->
            <div class="FontSize">
              <!-- {{
                    item.contact_email_id.length > 10
                      ? `${item.contact_email_id.slice(0, 10)}..`
                      : item.contact_email_id
                  }} -->
              {{ item.contact_email_id }}
            </div>
            <!-- </template>
              <span class="FontSize">{{ item.contact_email_id }}</span>
            </v-tooltip> -->
          </template>
          <template v-slot:[`item.issuer_created_on`]="{ item }">
            <div class="FontSize">
              {{
                new Date(item.issuer_created_on)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })
                  .replace(",", "")
              }}
              <!-- .split(",")[0] -->
            </div>
          </template>
          <template v-slot:[`item.total_credits`]="{ item }">
            <div class="FontSize">
              {{ item.total_credits }}
            </div>
          </template>
          <template v-slot:[`item.credits_issued`]="{ item }">
            <div class="FontSize">
              {{ item.credits_issued }}
            </div>
          </template>
          <!-- <template v-slot:[`item.issuer_type`]="{ item }">
            <div class="FontSize">
              {{ item.issuer_type != undefined ? item.issuer_type : "-" }}
            </div>
          </template> -->
          <template v-slot:[`item.available_credits`]="{ item }">
            <div class="FontSize">
              {{ item.available_credits }}
            </div>
          </template>
          <!-- <template v-slot:[`item.issuer_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.issuer_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'
              "
            >
              {{ item.issuer_status }}
            </div>
          </template> -->
          <template v-slot:[`item.source_type`]="{ item }">
            <div class="FontSize">
              {{ item.source_type == undefined ? "SC" : item.source_type }}
            </div>
          </template>
          <template v-slot:[`item.verification_status`]="{ item }">
            <div
              class="FontSize font-weight-bold text-uppercase"
              :class="
                item.verification_status == 'Verified'
                  ? 'success--text'
                  : 'error--text'
              "
            >
              {{
                item.verification_status !== undefined
                  ? item.verification_status
                  : "-"
              }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkItem(item, 'WALLET')"
                  >mdi-wallet</v-icon
                >
              </template>
              <span>Wallet Details</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkItem(item, 'VIEW')"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View / Modify</span>
            </v-tooltip>
            <!-- <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="green"
                  @click="checkItem(item, 'CREDITS')"
                  v-bind="attrs"
                  v-on="on"
                  small
                  >mdi-recycle</v-icon
                >
              </template>
              <span>Load Credits</span>
            </v-tooltip> -->

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="yellow"
                  class="mr-1"
                  @click="checkItem(item, 'CREDIT_HISTORY')"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  mdi-history</v-icon
                >
              </template>
              <span>Credit History</span>
            </v-tooltip> -->

            <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="blue"
                  @click="checkItem(item, 'CREDENTIAL')"
                  v-bind="attrs"
                  v-on="on"
                  small
                  >mdi-file-key-outline</v-icon
                >
              </template>
              <span>Transaction Logs</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.issuer_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'STATUS_CHANGE')"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  small
                  >{{
                    item.issuer_status == "ACTIVE"
                      ? "mdi-account-cancel"
                      : "mdi-account-plus"
                  }}
                  mdi-recycle</v-icon
                >
              </template>
              <span>{{
                item.issuer_status == "ACTIVE"
                  ? "Deactivate Issuer"
                  : "Activate Issuer"
              }}</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="success"
                  @click="checkItem(item, 'VERIFY')"
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ml-1"
                  >mdi-check-decagram</v-icon
                >
              </template>
              <span>Verify {{ item.issuer_name }}</span>
            </v-tooltip> -->
            <v-tooltip bottom v-if="item.verification_status == 'Due'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="checkItem(item, 'VERIFY')"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-autorenew</v-icon></v-btn
                >
              </template>
              <span>Renew Issuer</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.verification_status == 'Unverified'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="checkItem(item, 'VERIFY')"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-check-bold</v-icon></v-btn
                >
              </template>
              <span>Verify Issuer</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.issuer_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.verification_status == 'Trial'"
                  icon
                  color="success"
                  @click="checkItem(item, 'VERIFY')"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon small>mdi-check-decagram</v-icon>
                </v-btn>
              </template>
              <span>Verify Issuer</span>
            </v-tooltip>
            <v-card-actions
              v-if="item.issuer_status == 'PENDING'"
              class="pa-0"
              @click="$router.push('NewIssuerRequests')"
            >
              <div
                style="cursor: pointer"
                class="font-weight-bold OrangeHeader FontSizeSmall mr-2"
              >
                Approval Pending
              </div>
              <v-tooltip bottom color="#474747">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="primary" small
                    >mdi-share</v-icon
                  >
                </template>
                <span>Complete Approval Process</span>
              </v-tooltip>
            </v-card-actions>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <CredentialsIssued
      v-if="current_view == 'CREDENTIAL'"
      @clicked="current_view = 'LIST'"
      :StoreObj="StoreObj"
    />
    <CreditHistory
      v-if="current_view == 'CREDIT_HISTORY'"
      @clicked="current_view = 'LIST'"
      :StoreObj="StoreObj"
    />
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import CreateIssuerDialog from "@/components/Issuers/Dialogs/CreateIssuerDialog.vue";
import WalletDetailsDialog from "@/components/Issuers/Dialogs/WalletDetailsDialog.vue";
import AddRemoveCredit from "@/components/Issuers/Dialogs/AddRemoveCredit.vue";
import ActivateDeactivateIssuerDialog from "@/components/Issuers/Dialogs/ActivateDeactivateIssuerDialog.vue";
import CredentialsIssued from "@/components/Issuers/Cards/CredentialsIssued.vue";
import CreditHistory from "@/components/Issuers/Cards/CreditHistory.vue";
// import { GetAllIssuerUsers } from "@/mixins/GetAllIssuerUsers.js";
import { GetAllIssuerUsersFilter } from "@/mixins/GetAllIssuerUsersFilter.js";
import VerifyIssuerOrganization from "../../components/Issuers/Dialogs/VerifyIssuerOrganization";
export default {
  props: {
    routeLoadToggle: Boolean,
  },
  components: {
    ActivateDeactivateIssuerDialog,
    WalletDetailsDialog,
    CreateIssuerDialog,
    CredentialsIssued,
    VerifyIssuerOrganization,
    CreditHistory,
    AddRemoveCredit,
    Snackbar,
    Overlay,
  },
  mixins: [GetAllIssuerUsersFilter],
  data: () => ({
    search: "",
    overlay: false,
    StoreObj: {},
    SnackBarComponent: {},
    dialogwalletDetails: false,
    dialogAddRemoveCredit: false,
    dialogCreateIssuerUser: false,
    dialogVerifyIssuerOrganization: false,
    GetAllIssuerUsersFilterList: [],
    userHeaders: [
      {
        text: "Partner Code",
        value: "partner_code",
      },
      {
        text: "Name",
        value: "issuer_name",
      },
      {
        text: "Email ID",
        value: "contact_email_id",
      },
      {
        text: "Created On",
        value: "issuer_created_on",
      },
      // {
      //   text: "Issuer Type",
      //   value: "issuer_type",
      // },
      {
        text: "Source",
        value: "source_type",
      },

      // {
      //   text: "Total",
      //   value: "total_credits",
      // },
      // {
      //   text: "Consumed",
      //   value: "credits_issued",
      // },
      // {
      //   text: "Available",
      //   value: "available_credits",
      // },
      {
        text: "Issuer Verification Status",
        value: "verification_status",
      },
      // { text: "Status", value: "issuer_status" },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    dialogActivateInactivateIssuer: false,
    current_view: "LIST",
    verification_statusItems: ["ALL", "Due", "Verified", "Unverified"],
    verification_status: "ALL",
  }),
  watch: {
    routeLoadToggle() {
      this.current_view = "LIST";
    },
    verification_status(val) {
      this.GetAllIssuerUsersFilterMethod(val);
    },
  },
  mounted() {
    this.GetAllIssuerUsersFilterMethod(this.verification_status);
  },
  methods: {
    WalletDetailsDialogEmit() {
      this.dialogwalletDetails = false;
    },
    dialogActivateInactivateIssuerEmit(Toggle) {
      this.dialogActivateInactivateIssuer = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersFilterMethod(this.verification_status);
      }
    },
    addRemoveCreditDialogEmit(Toggle) {
      this.dialogAddRemoveCredit = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersFilterMethod(this.verification_status);
      }
    },
    dialogVerifyIssuerOrganizationEmit(Toggle) {
      this.dialogVerifyIssuerOrganization = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersFilterMethod(this.verification_status);
      }
    },
    checkItem(item, Action) {
      this.StoreObj = item;
      if (Action == "VIEW") {
        this.StoreObj.action = "VIEW";
        this.dialogCreateIssuerUser = true;
      } else if (Action == "CREDITS") {
        this.StoreObj.action = "CREDITS";
        this.dialogAddRemoveCredit = true;
      } else if (Action == "STATUS_CHANGE") {
        this.StoreObj.action = "STATUS_CHANGE";
        this.dialogActivateInactivateIssuer = true;
      } else if (Action == "CREDENTIAL") {
        this.current_view = "CREDENTIAL";
      } else if (Action == "CREDIT_HISTORY") {
        this.current_view = "CREDIT_HISTORY";
      } else if (Action == "WALLET") {
        this.dialogwalletDetails = true;
      } else if (Action == "VERIFY") {
        this.dialogVerifyIssuerOrganization = true;
      }
    },
    createIssuerDialogEmit(Toggle) {
      this.StoreObj = {};
      this.dialogCreateIssuerUser = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersFilterMethod(this.verification_status);
      }
    },
  },
};
</script>
<style>
.v-data-table th {
  white-space: nowrap !important;
}

.RedHeader {
  color: red;
}
.GreenHeader {
  color: green;
}
.YellowHeader {
  color: yellowgreen;
}
.BlueHeader {
  color: blue;
}
</style>
