<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      v-model="dialogVerifyIssuerOrganization"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <v-icon small class="mr-2">mdi-check</v-icon>
          <div>Verify {{ StoreObj.issuer_name }}</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogVerifyIssuerOrganizationEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text align="center">
          <!-- <v-icon
            class="mt-5"
            size="50px"
            :color="StoreObj.issuer_status == 'ACTIVE' ? 'red' : 'green'"
            >{{
              StoreObj.issuer_status == "ACTIVE" ? "mdi-cancel" : "mdi-check"
            }}</v-icon
          > -->
          <div>
            Are you Sure you want to verify
            {{ StoreObj.issuer_name }} ?
          </div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius"
            color="secondary"
            outlined
            @click="dialogVerifyIssuerOrganizationEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            class="mr-2 borderRadius"
            color="secondary"
            @click="verifyOrganization()"
            :loading="loading"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { VerifyOrganisation } from "@/graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default {
  props: {
    dialogVerifyIssuerOrganization: Boolean,
    StoreObj: Object,
  },

  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  components: {
    Snackbar,
  },
  methods: {
    async verifyOrganization() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          contact_email_id: this.StoreObj.contact_email_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(VerifyOrganisation, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.VerifyOrganisation);
        console.log("Result", ResultObject);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.status_Message,
          };
          
        }
        this.dialogVerifyIssuerOrganizationEmit(2);
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogVerifyIssuerOrganizationEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style></style>
