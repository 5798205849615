<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogAddRemoveCredit" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Add Additional Credits</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="addRemoveCreditDialogEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form">
            <v-select
              dense
              outlined
              class="field_height field_label_size FontSize mt-4"
              :rules="[(v) => !!v || 'Required']"
              label="Add Additional Credits *"
              :items="creditItems"
              v-model="create_credit.issuer_credits"
            ></v-select>
            <v-textarea
              rows="2"
              dense
              outlined
              class="field_height field_label_size FontSize"
              label="Comments *"
              v-model="create_credit.comments"
              :rules="[(v) => !!v || 'Required']"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="addRemoveCreditDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { UpdateIssuer } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogAddRemoveCredit: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    Toggle: 0,
    create_credit: {
      issuer_credits: null,
      comments: "",
    },
    SnackBarComponent: {},
    creditItems: ["50", "100", "500", "1000", "2500", "10000"],
  }),
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.addRemoveCreditMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks(*) are mandatory",
        };
      }
    },
    async addRemoveCreditMethod() {
      try {
        var inputParams = {
          issuer_id: this.StoreObj.issuer_id,
          user_email_id: this.$store.getters.get_user_email,
          update_type: "ADD_CREDITS",
          issuer_credits: parseInt(this.create_credit.issuer_credits, 10),
          comments: this.create_credit.comments,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(UpdateIssuer, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuer);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.overlay = true;
          this.addRemoveCreditDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    addRemoveCreditDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
